// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-ar-js": () => import("./../src/pages/about.ar.js" /* webpackChunkName: "component---src-pages-about-ar-js" */),
  "component---src-pages-about-en-js": () => import("./../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-contact-ar-js": () => import("./../src/pages/contact.ar.js" /* webpackChunkName: "component---src-pages-contact-ar-js" */),
  "component---src-pages-contact-en-js": () => import("./../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-events-ar-js": () => import("./../src/pages/events.ar.js" /* webpackChunkName: "component---src-pages-events-ar-js" */),
  "component---src-pages-events-en-js": () => import("./../src/pages/events.en.js" /* webpackChunkName: "component---src-pages-events-en-js" */),
  "component---src-pages-images-ar-js": () => import("./../src/pages/images.ar.js" /* webpackChunkName: "component---src-pages-images-ar-js" */),
  "component---src-pages-images-en-js": () => import("./../src/pages/images.en.js" /* webpackChunkName: "component---src-pages-images-en-js" */),
  "component---src-pages-index-ar-js": () => import("./../src/pages/index.ar.js" /* webpackChunkName: "component---src-pages-index-ar-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-our-message-ar-js": () => import("./../src/pages/our_message.ar.js" /* webpackChunkName: "component---src-pages-our-message-ar-js" */),
  "component---src-pages-our-message-en-js": () => import("./../src/pages/our_message.en.js" /* webpackChunkName: "component---src-pages-our-message-en-js" */),
  "component---src-pages-our-vision-ar-js": () => import("./../src/pages/our_vision.ar.js" /* webpackChunkName: "component---src-pages-our-vision-ar-js" */),
  "component---src-pages-our-vision-en-js": () => import("./../src/pages/our_vision.en.js" /* webpackChunkName: "component---src-pages-our-vision-en-js" */),
  "component---src-pages-projects-ar-js": () => import("./../src/pages/projects.ar.js" /* webpackChunkName: "component---src-pages-projects-ar-js" */),
  "component---src-pages-projects-en-js": () => import("./../src/pages/projects.en.js" /* webpackChunkName: "component---src-pages-projects-en-js" */),
  "component---src-pages-videos-ar-js": () => import("./../src/pages/videos.ar.js" /* webpackChunkName: "component---src-pages-videos-ar-js" */),
  "component---src-pages-videos-en-js": () => import("./../src/pages/videos.en.js" /* webpackChunkName: "component---src-pages-videos-en-js" */),
  "component---src-pages-who-we-are-ar-js": () => import("./../src/pages/who_we_are.ar.js" /* webpackChunkName: "component---src-pages-who-we-are-ar-js" */),
  "component---src-pages-who-we-are-en-js": () => import("./../src/pages/who_we_are.en.js" /* webpackChunkName: "component---src-pages-who-we-are-en-js" */),
  "component---src-templates-event-js": () => import("./../src/templates/Event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-project-js": () => import("./../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

